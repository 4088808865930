import { useQuery } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import supabase from '../lib/supabase';
import useTagStore from '../hooks/use-tag-store';
import VideoPlayer from './VideoPlayer';
import { useRoute } from 'wouter';

const CompactPlayer = () => {
  const [, params] = useRoute<{ id: string }>('/listen/:id');
  const tags = useTagStore(state => state.tags);
  const initialTrack = params?.id ?? null;
  const [currentTrack, setCurrentTrack] = useState(0);
  const [progressPct, setProgressPct] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [seekTo, setSeekTo] = useState<number | null>(null);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const nextTrack = () => {
    setCurrentTrack(currentTrack + 1);
  };

  const prevTrack = () => {
    setCurrentTrack(currentTrack > 0 ? currentTrack - 1 : currentTrack);
  };

  const handleSeek = (percentage: number) => {
    setProgressPct(percentage);
    setSeekTo(percentage);
  };

  const { isLoading, data: post } = useQuery({
    queryKey: ['playlist', { track: currentTrack }],
    queryFn: async () => {
      const result =
        currentTrack === 0 && initialTrack && initialTrack.length
          ? await supabase.from('uploads').select('*, profiles!profile_id(*)').eq('file', initialTrack).single()
          : await supabase
              .rpc('select_random_uploads_with_tags', {
                tag_list: tags,
                seed: Math.random(),
                limit_val: 1,
                offset_val: 0,
              })
              .single();
      console.log(result);
      return result as any;
    },
    staleTime: 365 * 24 * 60 * 60 * 1000,
    gcTime: 365 * 24 * 60 * 60 * 1000,
    refetchOnMount: false,
  });

  const containerEl = useRef<HTMLDivElement | null>(null);

  return (
    <div className="flex flex-col w-full items-center justify-center p-4">
      <div
        ref={containerEl}
        className="rounded-2xl max-w-[290px] sm:max-w-[390px] overflow-hidden border-card-border shadow-lg border-b-[4px] bg-card-background"
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col pb-10">
            {isLoading && <span className="p-2">Your audio feed is loading...</span>}
            {!isLoading && post?.data === null && (
              <span className="p-2 px-10">Nothing to load, the database is empty.</span>
            )}
            {!isLoading && post?.data && (
              <div className="flex flex-col">
                <div className="flex flex-row mb-5 px-2 pt-2">
                  <img
                    alt="User avatar"
                    src={post.data.profiles?.avatar ?? post.data.avatar}
                    className="w-[50px] h-[50px] rounded-full"
                  />
                  <div className="flex flex-col mx-2 flex-1 min-w-[0]">
                    <span className="text-card-subtext truncate">
                      @{post.data.profiles?.username ?? post.data.username}
                    </span>
                    <span className="font-semibold text-[#004080] truncate">{post.data.title}</span>
                  </div>
                </div>
                
                {/* Seek Bar */}
                <div className="px-4 w-full">
                  <div className="relative w-full h-8">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={progressPct}
                      className="range accent-[#005533] w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      onChange={(e) => handleSeek(Number(e.target.value))}
                    />
                    <div className="flex justify-between mt-1 text-xs text-gray-500">
                      <span>{formatTime(currentTime)}</span>
                      <span>{formatTime(duration)}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!isLoading && post?.data && (
        <VideoPlayer
          setProgressPct={setProgressPct}
          setCurrentTime={setCurrentTime}
          setDuration={setDuration}
          seekTo={seekTo}
          setSeekTo={setSeekTo}
          src={`https://fm-jacket-uploads.s3.us-west-2.amazonaws.com/${post.data.profile_id}/${post.data.file}.m3u8`}
          playNext={nextTrack}
          playPrev={prevTrack}
        />
      )}
    </div>
  );
};

export default CompactPlayer;