import { AppStoreButton, GooglePlayButton } from 'react-mobile-app-button';
import CompactPlayer from './CompactPlayer';
import DiscordButton from './DiscordButton';
import Transcript from './Transcript';

export default function Standalone() {
  return (
    <>
      <CompactPlayer />
      <div className='flex flex-row items-center justify-center gap-x-5'>
        <AppStoreButton
          className="my-2"
          url="https://apps.apple.com/us/app/jacket-fm/id6651823841"
          title="Download on the"
          theme="dark"
          height={60}
        />
        <GooglePlayButton
          className="my-2"
          url="https://play.google.com/store/apps/details?id=fm.jacket.radio"
          title="Get it on"
          theme="dark"
          height={60}
        />
        <DiscordButton className='my-2' />
      </div>
      <Transcript />
    </>
  );
}
