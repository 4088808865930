import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import ControlBar from './ControlBar';

interface VideoPlayerProps {
  src: string;
  setProgressPct: (pctProgress: number) => void;
  setCurrentTime: (time: number) => void;
  setDuration: (duration: number) => void;
  seekTo: number | null;
  setSeekTo: (value: number | null) => void;
  playNext: () => void;
  playPrev: () => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  setProgressPct,
  setCurrentTime,
  setDuration,
  seekTo,
  setSeekTo,
  playNext,
  playPrev,
}) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const rafRef = useRef<number | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

   // Handle seeking when seekTo value changes
   useEffect(() => {
    if (seekTo !== null && playerRef.current) {
      const newTime = (seekTo / 100) * playerRef.current.duration;
      playerRef.current.currentTime = newTime;
      setSeekTo(null); // Reset seekTo after handling
    }
  }, [seekTo, setSeekTo]);

  const handlePlayPause = () => {
    if (!playerRef.current) return;
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleForward = () => {
    if (!playerRef.current) return;
    playerRef.current.currentTime += 15;
  };

  const handleRewind = () => {
    if (!playerRef.current) return;
    playerRef.current.currentTime -= 15;
  };

  const handleProgress = useCallback(() => {
    if (playerRef.current) {
      const duration = playerRef.current.duration;
      const currentTime = playerRef.current.currentTime;
      const progressPercentage = (currentTime / duration) * 100;
      
      setProgressPct(progressPercentage);
      setCurrentTime(currentTime);
      setDuration(duration);
    }
    rafRef.current = requestAnimationFrame(handleProgress);
  }, [setProgressPct, setCurrentTime, setDuration]);

  const handlePlay = () => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
    rafRef.current = requestAnimationFrame(handleProgress);
  };

  const handlePause = () => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
  };

  useEffect(() => {
    let refHandle: HTMLVideoElement | null;
    // Set initial duration when media is loaded
    const handleLoadedMetadata = () => {
      if (playerRef.current) {
        setDuration(playerRef.current.duration);
      }
    };

    if (playerRef.current) {
      refHandle = playerRef.current;
      refHandle.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    return () => {
      if (refHandle) {
        refHandle.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, [setDuration]);

  useEffect(() => {
    rafRef.current = requestAnimationFrame(handleProgress);
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [handleProgress]);

  return (
    <div>
      <ControlBar
        isPlaying={isPlaying}
        onPlayPause={handlePlayPause}
        onForward={handleForward}
        onRewind={handleRewind}
        onNext={playNext}
        onPrevious={playPrev}
      />
      <ReactHlsPlayer
        playerRef={playerRef}
        onPlay={handlePlay}
        onPause={handlePause}
        src={src}
        autoPlay={false}
        controls={false}
        width="100%"
        height="0"
        className="hidden"
        onProgress={handleProgress}
        playsInline
      />
    </div>
  );
};

export default VideoPlayer;